import * as React from "react"
import { Card, Tabs } from "antd"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { FindUs } from "../components/FindUs"
import { ContactForm } from "../components/ContactForm"

const StyledCard = styled(Card)`
  .ant-card-head {
    font-size: 4vw;
    font-family: "JosefinSans";
    text-align: center;
    border-bottom: 0px;
    @media only screen and (max-width: 800px) {
      font-size: 35px;
    }
  }
`

const StyledTabs = styled(Tabs)`
  font-size: 16px;
`

const { TabPane } = Tabs

const Contact = () => {
  const callback = key => {}

  return (
    <>
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
      </Helmet>
      <StyledCard title="Contact">
        <StyledTabs
          defaultActiveKey="1"
          onChange={callback}
          size="large"
          centered
        >
          <TabPane tab="Find Us" key="find">
            <FindUs />
          </TabPane>
          <TabPane tab="Ask Us" key="message">
            <ContactForm />
          </TabPane>
        </StyledTabs>
      </StyledCard>
    </>
  )
}

export default Contact
