import * as React from "react"
import styled from "styled-components"

const mapStyle = {
  border: "0",
  borderRadius: "15px",
}

const StyledGroup = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
`

const StyledH2 = styled.h2`
  font-size: 20px;
`

export const FindUs = () => {
  return (
    <>
      <StyledGroup>
        <StyledH2>Email:</StyledH2>
        <a
          href="mailto:tarun@tjbuildingdesigners.com.au"
          target="_blank"
          rel="noreferrer"
        >
          tarun@tjbuildingdesigners.com.au
        </a>
      </StyledGroup>
      <StyledGroup>
        <StyledH2>Phone:</StyledH2>
        <a href="tel:0431287724" target="_blank" rel="noreferrer">
          0431 287 724
        </a>
      </StyledGroup>
      <StyledGroup>
        <StyledH2>Address:</StyledH2>
        <iframe
          title="address-map"
          allowFullScreen=""
          frameBorder="0"
          height="450"
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBgyxfxFGPZFD3I-Si88m7hmT0LplqQHgo&q=71+Kempsie+Road"
          style={mapStyle}
          width="100%"
        ></iframe>
      </StyledGroup>
    </>
  )
}
