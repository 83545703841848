import * as React from "react"
import styled from "styled-components"
import { Form, Input, Button, message } from "antd"
import emailjs from "emailjs-com"

const ReactRecaptcha = require("react-recaptcha")

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 16,
  },
}

const StyledGroup = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 21%;
  @media only screen and (max-width: 575px) {
    margin-left: 0%;
  }
`

export const ContactForm = () => {
  const [captchaVerified, setCaptchaVerified] = React.useState(false)
  const [captchaMessage, setCaptchaMessage] = React.useState(
    "Captcha failed to verify"
  )
  const onFinish = values => {
    if (captchaVerified) {
      const hideLoading = message.loading("Sending email...", 0)
      emailjs
        .send(
          process.env.GATSBY_EMAILJS_SERVICE_ID,
          process.env.GATSBY_EMAILJS_TEMPLATE_ID,
          {
            from_name: values.name,
            reply_to: values.email,
            message: values.message,
          },
          process.env.GATSBY_EMAILJS_USER_ID
        )
        .then(response => {
          hideLoading()
          message.success("Message sent successfully!")
          console.log("SUCCESS!", response.status, response.text)
        })
        .catch(err => {
          hideLoading()
          console.log("FAILED...", err)
          message.error("Message failed to send!")
        })
    } else {
      message.error(captchaMessage)
    }
  }

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo)
    message.error("Message failed to send!")
  }

  const recaptchaCallback = value => {
    setCaptchaVerified(true)
  }

  const recaptchaExpiredCallback = value => {
    setCaptchaVerified(false)
    setCaptchaMessage("Captcha expired. Please verify again!")
  }

  return (
    <>
      <StyledGroup>
        <Form
          {...layout}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Message"
            name="message"
            rules={[
              {
                required: true,
                message: "Please input your message!",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <StyledButtonContainer>
            <Form.Item>
              <ReactRecaptcha
                sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                theme="dark"
                size="normal"
                verifyCallback={recaptchaCallback}
                expiredCallback={recaptchaExpiredCallback}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </StyledButtonContainer>
        </Form>
      </StyledGroup>
    </>
  )
}
